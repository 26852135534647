import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";

export const firebaseConfig = {
    apiKey: "AIzaSyD66Pg0s20be-L1lod3A29C8uyehouZREE",
    authDomain: "uberprints-f0132.firebaseapp.com",
    databaseURL: "https://uberprints-f0132-default-rtdb.firebaseio.com/",
    projectId: "uberprints-f0132",
    storageBucket: "uberprints-f0132.appspot.com",
    messagingSenderId: "456610360844",
    appId: "1:456610360844:web:8a319bbd474ccf71a50829",
    measurementId: "G-8YZL6V459X",
    SHA1: "1B:CF:19:BB:6E:F8:F9:EA:D3:25:02:5C:89:EB:AD:42:E5:D4:0C:A3",
    SHA256: "B2:45:E4:CE:80:4D:75:B6:32:A1:24:BF:97:08:F0:7E:68:66:D8:0E:20:E2:34:86:C6:13:47:CE:9A:88:BF:4E",
    FIREBASE_TOKEN: "1//01kjZtFTqiHYmCgYIARAAGAESNwF-L9IrqLj3xSfkYFa-eiU4iSAhPYgHjxMdyJLssaAZfaUTKgi1nA6pBdsiyx_nAk17fmtdjVo"
  };
export const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
export const API_KEY = "AIzaSyD66Pg0s20be-L1lod3A29C8uyehouZREE"