import React from 'react'
import LoginForm from './LoginForm'



const LoginExport = props => {
  return (
      <LoginForm />
  )
}
export default LoginExport
  
 
  



  
