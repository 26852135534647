import React from 'react'
import RegisterForm from './RegisterForm'
import {Paper} from '@mui/material'
import { makeStyles } from '@mui/styles'
import styles from '../Auth/register.module.css'

const RegisterExport = props => {
  return (
      <RegisterForm />
  )
}
export default RegisterExport
  
 
  



  
